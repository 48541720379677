import classNames from 'classnames'
import React from 'react'

import { useThemeSettings } from 'customizer/theme'
import OutOfStockIcon from 'icons/regular/01-Interface Essential/23-Delete/delete.svg'

import './OutOfStockBadge.scss'

export interface OutOfStockBadgeProps {
  className?: string
  style?: React.CSSProperties
}

const OutOfStockBadge = ({ className, style }: OutOfStockBadgeProps) => {
  const outOfStockStyle = useThemeSettings('outOfStock')

  const badgeStyle: React.CSSProperties = {
    backgroundColor: outOfStockStyle.badgeBackgroundColor,
    fill: outOfStockStyle.badgeIconColor,
    color: outOfStockStyle.badgeIconColor,
    border: `${outOfStockStyle.badgeBorderWidth} solid ${outOfStockStyle.badgeBorderColor}`,
    ...style,
  }

  return (
    <div role="alert" className={classNames('out-of-stock__badge', className)} style={badgeStyle}>
      <OutOfStockIcon aria-label="Out of stock icon" />
    </div>
  )
}

export default OutOfStockBadge
